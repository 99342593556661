.clearfix:after {
    content: "";
    display: table;
    clear: both;
}
*:focus {
    outline: 0;
}
.mt10 {
    margin-top: 10px;
}
.mt15 {
    margin-top: 15px;
}
.mt20 {
    margin-top: 20px;
}
.button {
    border: 0;
    cursor: pointer;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    padding: 0 15px;
    vertical-align: middle;
    background: #489D44;
    color: #fff;
    font-weight: 700;
    border-radius: 3px;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
}
.button.state-open {
    background: #fff;
    color: #489D44;
}
.button.state-open:hover {
    color: #489D44;
    background: #fff;
}
.button.white {
    line-height: 28px;
    background: #fff;
    height: 30px;
    color: #489D44;
    border: 1px solid #d9d9d9;
}
.button.white:hover {
    color: #489D44;
}
.button.red {
    background: #FF0000;
    color: #FFFFFF;
    border: 1px solid #FF0000;
}
.button.open i.fa-chevron-down:before {
    content: "\f077";
}
.button.tiny {
    font-size: 11px;
}
.button.big {
    height: 40px;
    line-height: 40px;
}
.button:hover {
    color: #fff;
}
.button i.fa-chevron-down {
    margin-top: -3px;
    vertical-align: middle;
    margin-left: 3px;
}
.button.toggle-comment {
    margin-top: 10px;
}
.button.fl-r {
    float: right;
    margin-left: 10px;
}
.file-upload:after {
    content: "";
    display: table;
    clear: both;
}
.file-upload .action:after {
    content: "";
    display: table;
    clear: both;
}
.file-upload .action .add {
    float: left;
}
.file-upload .action .count {
    font-size: 13px;
    float: right;
}
.file-upload .action .count span {
    font-weight: 700;
    color: #000;
}
.file-upload .content {
    margin: 20px -5px 0 -5px;
}
.file-upload .content:after {
    content: "";
    display: table;
    clear: both;
}
.file-upload .content .thumbnail {
    padding-bottom: 100%;
    padding: 0 5px;
    float: left;
    width: 10%;
    height: 20px;
    position: relative;
}
.file-upload .content .thumbnail .thumbnail-box {
    position: relative;
    border: 1px solid #d9d9d9;
    background: #fff;
    -webkit-box-shadow: inset 0 0 0 4px #f5f5f5;
    box-shadow: inset 0 0 0 4px #f5f5f5;
}
select {
    padding: 0 5px;
    font-size: 12px;
    cursor: pointer;
    width: 100%;
    height: 25px;
    border: 1px solid #ced2cc;
    border-radius: 3px;
    display: block;
}
#mobile-menu {
    display: none;
    margin-left: 15px;
    background: none;
    float: right;
    border: 0;
    font-size: 0;
}
#mobile-menu.open {
    color: #489D44;
}
#mobile-menu i {
    vertical-align: middle;
    font-size: 30px;
}
.relative {
    position: relative;
}
.relative:after {
    content: "";
    display: table;
    clear: both;
}
body {
    height: 100%;
    min-height: 100%;
    font-family: "Open Sans", sans-serif;
    background: #ededed;
    font-size: 13px;
}
body.index header {
    height: 550px;
}
body.index .page-header {
    margin-top: -330px;
}
body.index form.search {
    display: block !important;
}
body.index form.search .bottom {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
}
body.with-sidebar .page-header {
    margin-top: -145px;
}
body.with-sidebar .page-header .page-title {
    line-height: normal;
}
body.with-sidebar header {
    height: 165px;
}
body.textpage .page-header {
    margin-top: -105px;
}
body.textpage header {
    height: 125px;
}
body.textpage #content .box {
    padding: 50px;
    min-height: 800px;
}
body.productpage #content .box {
    min-height: 800px;
}
body.productpage header {
    height: 170px;
}
body.productpage .page-header {
    margin-top: -150px;
}
body.productpage .page-header .content {
    padding-bottom: 0;
}
#top {
    padding: 15px 0;
    height: 86px;
    line-height: 56px;
    font-size: 0;
    color: #999;
}
#top a.logo {
    display: inline-block;
    vertical-align: middle;
    border-right: 1px solid #d5d5d5;
    padding-right: 30px;
    margin-right: 30px;
}
#top .slogan {
    vertical-align: middle;
    font-size: 13px;
    line-height: 18px;
    display: inline-block;
    font-family: "Bitter", sans-serif;
}
#top .slogan strong {
    color: #489D44;
}
#top .langs {
    float: right;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    list-style: none;
}
#top .langs li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
}
#top .langs li:last-child {
    margin-right: 0;
}
#top .langs li a {
    text-align: center;
    line-height: 28px;
    text-transform: uppercase;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #999;
    color: #999;
    font-size: 10px;
}
#top .langs li a:hover {
    background: #489D44;
    color: #fff;
    text-decoration: none;
    border: 1px solid #489D44;
}
#top nav {
    margin-right: 30px;
    border-right: 1px solid #d5d5d5;
    float: right;
}
#top nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
#top nav ul li {
    margin-right: 30px;
    display: inline-block;
    height: 56px;
    padding-top: 11px;
    vertical-align: top;
    line-height: normal;
}
#top nav ul a {
    color: #999;
    font-size: 13px;
}
#top nav ul .link {
    font-size: 0;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;
    height: 34px;
}
#top nav ul .link.statybu {
    width: 132px;
    background-image: url('/img/front/statybu/logo.png');
}
#top nav ul .link.ukio {
    width: 125px;
    background-image: url('/img/front/ukio/logo.png');
}
#top nav ul .link.komunaline {
    width: 128px;
    background-image: url('/img/front/komunaline/logo.png');
}
#top nav ul .link.misko {
    width: 130px;
    background-image: url('/img/front/misko/logo.png');
}
header {
    padding-top: 20px;
    background: url('/img/front/misko/bg-header.jpg') no-repeat top center;
    height: 160px;
}
header .ads {
    height: 180px;
    background: #eee;
    margin-bottom: 20px;
}
#content {
    color: #888888;
}
#content p {
    margin: 0 0 15px 0;
    line-height: 20px;
}
#content p:last-child {
    margin-bottom: 0;
}
#content .group-content {
    margin-top: 25px;
}
#content .group-content .title {
    margin-bottom: 5px;
    font-weight: 700;
}
#content .group-content a {
    color: #489D44;
}
#content .group-content:first-child {
    margin-top: 0;
}
#content .ads {
    margin-bottom: 20px;
    text-align: center;
}
#content .box {
    padding: 20px;
    background: #fff;
}
#content .gbox {
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    padding: 20px;
    background: #fafafa;
}
#content .section-title {
    margin-bottom: 10px;
}
#content .section-title > div {
    color: #489D44;
    font-size: 16px;
    font-weight: 700;
    font-family: "Bitter", sans-serif;
    text-transform: uppercase;
    display: inline-block;
    padding-bottom: 3px;
    border-bottom: 2px solid #489D44;
}
.user-tabs a {
    color: #489D44;
    text-decoration: underline;
}
.user-tabs a:hover {
    text-decoration: none;
}
.user-tabs .list-tabs {
    padding: 0;
    margin: 0;
    list-style: none;
}
.user-tabs .list-tabs:after {
    content: "";
    display: table;
    clear: both;
}
.user-tabs .list-tabs li {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    margin-right: 3px;
    padding: 0 20px;
    float: left;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    border: 1px solid #d9d9d9;
}
.user-tabs .list-tabs li:hover {
    text-decoration: underline;
}
.user-tabs .list-tabs li.active {
    border-bottom: 0;
    background: #fff;
    font-weight: 700;
    color: #489D44;
    text-decoration: none;
}
.user-tabs .list-tabs-content {
    display: none;
    margin-top: -1px;
    padding: 20px;
    background: #fff;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    border: 1px solid #d9d9d9;
}
footer {
    color: #fff;
    background: url('/img/front/misko/bg-footer.jpg') no-repeat top center;
}
footer .copyrights {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin: 10px 0;
    text-transform: uppercase;
    font-weight: 700;
}
footer .copyrights:after {
    content: "";
    display: table;
    clear: both;
}
footer .copyrights .sitename {
    padding-right: 15px;
    margin-right: 15px;
    float: left;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}
footer .copyrights .backtotop {
    float: right;
    color: #fff;
}
footer .copyrights .box {
    height: 30px;
    line-height: 30px;
    margin-bottom: 10px;
}
footer nav .title {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
}
footer nav .title:after {
    margin-top: 10px;
    margin-bottom: 10px;
    content: "";
    width: 30px;
    background: rgba(255, 255, 255, 0.1);
    height: 1px;
    display: block;
}
footer nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
footer nav ul li {
    display: block;
    margin-bottom: 5px;
}
footer nav ul li:last-child {
    margin-bottom: 0;
}
footer nav ul li a {
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
footer nav ul li a:hover {
    border-bottom: 0;
    text-decoration: none;
    color: #FFFFFF;
}
footer nav .text {
    color: #fff;
    line-height: 24px;
}
footer .information {
    border-top: 1px solid #469150;
    margin-top: 20px;
    background: rgba(0, 0, 0, 0.1);
    padding: 20px 0;
    font-size: 13px;
}
footer .information .author {
    float: right;
    color: #B0D4AA;
}
footer .information .author a {
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
}
.filters {
    margin-bottom: 20px;
}
.filters .sort:after {
    content: "";
    display: table;
    clear: both;
}
.filters:after {
    content: "";
    display: table;
    clear: both;
}
.filters select {
    height: 25px;
    float: right;
    max-width: 190px;
    border: 1px solid #cccccc;
}
.filters:last-of-type {
    margin-bottom: 0;
}
.filters:last-of-type .pager {
    margin-bottom: 0;
}
.pager {
    float: left;
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 0;
}
.pager li {
    margin-right: 10px;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
}
.pager li:last-child {
    margin-right: 0;
}
.pager li.active a {
    font-weight: 700;
    border: 1px solid #489D44;
    color: #fff;
    background: #489D44;
}
.pager li a {
    border-radius: 3px;
    line-height: 23px;
    width: 25px;
    height: 25px;
    display: block;
    border: 1px solid #cccccc;
    color: #999999;
    font-size: 13px;
    text-decoration: none;
}
.pager li a:hover {
    font-weight: 700;
    border: 1px solid #489D44;
    color: #fff;
    background: #489D44;
}
.side-menu {
    margin: 0;
    padding: 0;
    list-style: none;
}
.side-menu li {
    display: block;
    border-bottom: 1px solid #d7d7d7;
}
.side-menu li a {
    font-size: 13px;
    border-radius: 3px;
    display: block;
    height: 40px;
    line-height: 40px;
    color: #888;
    padding: 0 15px;
}
.side-menu li.active {
    font-weight: 700;
    border-bottom: 0;
}
.side-menu li.active a {
    background: #489D44;
    color: #fff;
    text-decoration: none;
}
.section-title {
    font-family: "Bitter", sans-serif;
    font-size: 21px;
    color: #489D44;
}
.section-title span {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 300;
    color: #999999;
}
.page-header {
    margin-top: -115px;
}
.page-header .search-options,
.page-header .page-image {
    margin-right: 15px;
    float: left;
    max-width: 170px;
    width: 100%;
    height: 70px;
    background: #fff;
    border: 1px solid #dce0db;
    white-space: nowrap;
}

.page-header .search-options i {
    font-size: 16px;
    margin-right: 10px;
}

.page-header .page-content:after {
    content: "";
    display: table;
    clear: both;
}
.page-header .page-title {
    display: inline-block;
    line-height: 40px;
    font-family: "Bitter", sans-serif;
    font-size: 21px;
    color: #489D44;
    word-wrap: break-word;
}
.page-header .page-title span {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 300;
    color: #999999;
}
.page-header .page-links {
    display: block;
    -webkit-box-shadow: inset 0 1px 0 0 #fff;
    box-shadow: inset 0 1px 0 0 #fff;
    border-top: 1px solid #dfe8e0;
    margin: 15px -20px 0 -20px;
    height: 35px;
    padding: 5px 0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f3f8f4+0,fefffe+100 */
    background: #f3f8f4;
    /* Old browsers */
    background: -moz-linear-gradient(top, #f3f8f4 0%, #fefffe 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f3f8f4), color-stop(100%, #fefffe));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #f3f8f4 0%, #fefffe 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #f3f8f4 0%, #fefffe 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #f3f8f4 0%, #fefffe 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #f3f8f4 0%, #fefffe 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3f8f4', endColorstr='#fefffe', GradientType=0);
    /* IE6-9 */
}
.page-header .page-links ul {
    width: 100%;
    display: table;
    padding: 0;
    margin: 0;
    list-style: none;
}
.page-header .page-links ul li {
    line-height: 25px;
    vertical-align: middle;
    text-align: center;
    display: table-cell;
    width: auto;
    border-right: 1px solid #dfe8e0;
}
.page-header .page-links ul li a {
    color: #888;
    text-decoration: none;
}
.page-header .page-links ul li i {
    margin-top: -3px;
    vertical-align: middle;
    margin-right: 3px;
    font-size: 14px;
}
.page-header .page-links ul li i.fa-facebook-square {
    color: #3b5998;
}
.page-header .page-links ul li i.fa-exclamation-triangle {
    color: #d81500;
}
.page-header .page-links ul li:last-child {
    border-right: 0;
}
.page-header .menu:after {
    content: "";
    display: table;
    clear: both;
}
.page-header .menu ul {
    float: left;
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;
    height: 40px;
    font-size: 0;
}
.page-header .menu ul li {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    overflow: hidden;
    font-size: 13px;
    display: inline-block;
    padding: 0 20px;
    background: rgba(0, 0, 0, 0.25);
    margin-right: 2px;
}
.page-header .menu ul li i {
    font-size: 15px;
    margin-right: 5px;
}
.page-header .menu ul li:hover {
    background: rgba(0, 0, 0, 0.35);
}
.page-header .menu ul li:last-child {
    margin-right: 0;
}
.page-header .menu ul li.active,
.page-header .menu ul li.highlight {
    background: #fafdf9;
}
.page-header .menu ul li.active a,
.page-header .menu ul li.highlight a {
    color: #489D44;
    border-bottom: 2px solid #489D44;
}
.page-header .menu ul li.highlight a {
    border-bottom: 0;
}
.page-header .menu ul li a {
    color: #fff;
    font-weight: 700;
    height: 40px;
    display: block;
    line-height: 40px;
    text-decoration: none;
}
.page-header .menu ul.right {
    float: right;
}
.page-header .menu ul.right li {
    margin-right: 0;
    margin-left: 5px;
}
.page-header .menu ul.right li:first-child {
    margin-left: 0;
}
.page-header .content {
    color: #9b9f9a;
    padding: 20px 20px 15px 20px;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fafdf9+0,e6efe6+100 */
    background: #fafdf9;
    /* Old browsers */
    background: -moz-linear-gradient(top, #fafdf9 0%, #e6efe6 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fafdf9), color-stop(100%, #e6efe6));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #fafdf9 0%, #e6efe6 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #fafdf9 0%, #e6efe6 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #fafdf9 0%, #e6efe6 100%);
    /* IE10+ */
    background: linear-gradient(to bottom, #fafdf9 0%, #e6efe6 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fafdf9', endColorstr='#e6efe6', GradientType=0);
    /* IE6-9 */

}
form label {
    font-size: 13px;
    display: block;
    margin-bottom: 5px;
}
form label.checkbox {
    cursor: pointer;
    font-size: 0;
    margin-bottom: 0;
    line-height: 23px;
    height: 25px;
}
form label.checkbox span {
    display: inline-block;
    vertical-align: middle;
    font-size: 13px;
    margin-left: 5px;
}
form label[for=""] {
    cursor: pointer;
}
form input[type=checkbox] {
    display: inline-block;
    vertical-align: middle;
}
form input[type=text],
form input[type=password],
form input[type=number],
form textarea {
    resize: none;
    width: 100%;
    font-size: 12px;
    padding: 0 8px;
    height: 30px;
    border: 1px solid #ced2cc;
    border-radius: 3px;
}
form textarea {
    min-height: 140px;
    padding: 8px;
}
form button[type=submit] {
    float: right;
}
form button[type=submit] i {
    font-size: 16px;
    margin-right: 5px;
}
form .bottom {
    border-bottom: 1px solid #e5e9e4;
    padding-bottom: 15px;
    margin-bottom: 20px;
    padding-top: 20px;
    border-top: 1px solid #e5e9e4;
}
.group + .group {
    margin-bottom: 15px;
}
form.form-filter .row + .row {
    margin-top: 20px;
}
form.form-filter .group + .group {
    margin-bottom: 10px;
}
form.form-filter .form-title {
    margin-bottom: 15px;
    font-weight: 700;
    color: #489D44;
    position: relative;
}
form.form-filter .form-title span {
    position: relative;
    z-index: 5;
    background: #fff;
    padding-right: 20px;
}
form.form-filter .form-title:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background: #e5e5e5;
    top: 10px;
    left: 0;
}
form.form-filter label.checkbox {
    line-height: 69px;
}
form.contact {
    margin-top: 40px;
    padding: 25px;
    background: #f7f7f7;
    border: 1px solid #d2d2d2;
    border-radius: 3px;
}
form.contact:after {
    content: "";
    display: table;
    clear: both;
}
form.contact .form-title {
    margin-bottom: 10px;
    font-family: "Bitter", sans-serif;
    font-size: 21px;
    color: #489D44;
}
form.contact .group {
    margin-bottom: 20px;
}
form.contact .group:last-child {
    margin-bottom: 0;
}
form.search {
    display: none;
}
form.search input[type=text] {
    height: 25px;
}
.list-popular {
    list-style: none;
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: 0;
    padding-left: 0;
}
.list-popular li + li {
    margin-bottom: 20px;
}
.list-popular li .item {
    border: 1px solid #e5e5e5;
}
.list-popular li .image {
    margin: -1px;
    height: 125px;
    background: #e5e5e5;
}
.list-popular li .content {
    padding: 7px;
}
.list-popular li .title,
.list-popular li .price span {
    color: #666666;
}
.list-popular li .price {
    margin-top: 5px;
    padding-top: 5px;
    border-top: 1px solid #e5e5e5;
    color: #489D44;
    font-weight: 700;
}
.list-popular li .price span {
    font-weight: normal;
    font-size: 10px;
}
.list-news {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}
.list-news li {
    margin-bottom: 20px;
}
.list-news li:last-child {
    margin-bottom: 0;
}
.list-news li .item {
    border: 1px solid #e5e5e5;
    background: #f8f8f8;
}
.list-news li .item:after {
    content: "";
    display: table;
    clear: both;
}
.list-news li .image {
    margin: -1px;
    width: 135px;
    height: 205px;
    float: left;
    background: #e5e5e5;
}
.list-news li .content {
    padding: 20px;
    overflow: hidden;
}
.list-news li .title {
    font-size: 13px;
    font-weight: 700;
    color: #666;
}
.list-news li .date,
.list-news li .description {
    color: #999;
}
.list-news li .description {
    margin-top: 15px;
    margin-bottom: 20px;
}
.list-items {
    padding: 0;
    margin: 0;
    list-style: none;
}
.list-items li {
    color: #999999;
    display: block;
    border: 1px solid #d9d9d9;
    padding: 14px;
    margin-bottom: 20px;
}
.list-items li:after {
    content: "";
    display: table;
    clear: both;
}
.list-items li:hover {
    background: #fafafa;
}
.list-items .image {
    background: #d9d9d9;
    overflow: hidden;
    width: 115px;
    height: 85px;
    float: left;
    margin-right: 15px;
}
.list-items .right {
    float: right;
    text-align: right;
}
.list-items .right .prices .price {
    display: block;
    color: #489D44;
    font-size: 15px;
    font-family: "Bitter", sans-serif;
}
.list-items .right .prices .price.ltl {
    color: #999999;
    font-size: 12px;
}
.list-items .right .manufacturer {
    overflow: hidden;
    width: 100px;
    height: 40px;
    background: #eee;
    margin-top: 5px;
}
.list-items .content {
    max-width: 400px;
    overflow: hidden;
}
.list-items .title {
    font-size: 15px;
    font-family: "Bitter", sans-serif;
    color: #333333;
}
.list-items .title a {
    color: #333;
    text-decoration: none;
}
.list-items .desc {
    margin-top: 5px;
}
.list-items .options {
    margin-top: 5px;
    font-size: 0;
}
.list-items .options a,
.list-items .options div {
    color: #999999;
    font-size: 13px;
    display: inline-block;
    text-decoration: none;
}
.list-items .options a i,
.list-items .options div i {
    font-size: 11px;
}
.list-items .options a:last-child:after,
.list-items .options div:last-child:after {
    content: none;
}
.list-items .options a:after,
.list-items .options div:after {
    margin: 0 10px;
    content: "|";
    color: #d5d5d5;
}
#ads {
    z-index: 5;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
#ads .item {
    height: 400px;
    background: #489D44;
    margin-bottom: 20px;
}
#ads .item:last-child {
    margin-bottom: 0;
}
.widget-contacts {
    margin-top: 20px;
}
.widget-contacts .box {
    padding: 15px;
    background: #f7f7f7;
    border: 1px solid #d9d9d9;
    -webkit-box-shadow: inset 0 0 0 1px #fff;
    box-shadow: inset 0 0 0 1px #fff;
}
.widget-contacts .title {
    display: block;
    font-size: 13px;
    color: #888;
    font-weight: 700;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #d9d9d9;
}
.widget-contacts .group {
    font-size: 11px;
    color: #888;
    margin-bottom: 10px;
}
.widget-contacts .button {
    height: 40px;
    line-height: 40px;
    margin-top: -1px;
    border-radius: 0;
    font-weight: normal;
    text-align: center;
    display: block;
}
.widget-contacts .button i {
    margin-right: 5px;
}
.product a {
    color: #489D44;
}
.product .product-group-title {
    margin-bottom: 5px;
    font-weight: 700;
    color: #489D44;
    font-size: 13px;
}
.product .product-main-image {
    width: 100%;
    background: #eee;
}

.product .product-main-image img{
    max-height: 250px;
    margin: auto;
}

.product .product-thumbnails {
    margin: 10px -5px 0 -5px;
    list-style: none;
    padding: 0;
}
.product .product-thumbnails:after {
    content: "";
    display: table;
    clear: both;
}
.product .product-thumbnails li {
    padding: 0 4px;
    margin-bottom: 5px;
    float: left;
    width: 20%;
    overflow: hidden;
}
.product .product-thumbnails li .thumbbox {
    cursor: pointer;
    padding: 3px;
    display: block;
    background: #fff;
    height: 72px;
    text-align: center;
}
.product .product-thumbnails li .thumbbox.active {
    -webkit-box-shadow: inset 0 0 0 3px #489D44;
    box-shadow: inset 0 0 0 3px #489D44;
}
.product .product-thumbnails li .thumbbox img {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100%;
    width: 100%;
    height: auto;
    vertical-align: middle;
    display: inline-block;
}
.product .product-price {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #f2f2f2;
}
.product .product-price span {
    margin-right: 5px;
    font-weight: 600;
    font-size: 28px;
    color: #489D44;
    font-family: "Bitter", sans-serif;
}
.product .product-price > div {
    margin-top: -5px;
}
.product .product-info {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #f2f2f2;
}
.product .product-info:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
}
.product .product-info .value {
    color: #000;
}
.product .product-info .row + .row {
    margin-top: 7px;
}
.product .product-info.comments {
    padding-bottom: 0;
    margin-bottom: 0;
    -webkit-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    -ms-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    transition: all 400ms ease;
    height: 140px;
    overflow: hidden;
    border-bottom: 0;
    height: auto;
}
/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
    body.with-sidebar .pager {
        float: none;
        margin-bottom: 5px;
    }
    body.with-sidebar .pager li {
        margin-bottom: 10px;
    }
    body.with-sidebar .filters {
        float: none;
    }
    body.with-sidebar .filters select {
        max-width: inherit;
        float: none;
        width: 100%;
    }
    #top nav ul .link.statybu {
        width: 42px;
    }
    #top nav ul .link.komunaline {
        width: 36px;
    }
    #top nav ul .link.ukio {
        width: 36px;
    }
    #top nav ul .link.misko {
        width: 40px;
    }
    form.search .group .group + .group {
        margin-top: 10px;
    }
    body.index header {
        background-size: cover;
        height: 585px;
    }
    body.index .page-header {
        margin-top: -365px;
    }
}
/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
    #top a.logo {
        margin-right: 0;
        padding-right: 0;
        border-right: 0;
    }
    body.index .page-header {
        margin-top: -365px;
    }
    body.index header {
        height: 385px;
    }
    footer {
        background-size: cover;
    }
    footer .group {
        margin-top: 20px;
    }
    .product .product-thumbnails li {
        width: 25%;
    }
}
/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
    .page-header .page-title {
        display: block;
    }
    .product .product-main-image {
        margin-top: 20px;
    }
    .product .product-price {
        display: block;
    }
    .product .product-price span {
        display: block;
    }
    .product .product-thumbnails li {
        width: 16.66666666666667%;
    }
    body.productpage header {
        height: 135px;
    }
    body.productpage .page-header {
        margin-top: -115px;
    }
    body.productpage .page-header .content {
        padding-bottom: 15px;
    }
    .widget-contacts {
        margin-bottom: 15px;
    }
    .side-menu {
        margin-bottom: 20px;
    }
    .list-items .content {
        padding-top: 10px;
        overflow: visible;
        clear: both;
    }
    .list-items .prices {
        margin-top: 6px;
        float: left;
    }
    .list-items .image {
        width: 100%;
        margin-bottom: 5px;
    }
    .list-items .manufacturer {
        float: right;
    }
    .list-items .right {
        text-align: left;
        clear: both;
        float: none;
    }
    #mobile-menu {
        display: block;
    }
    .page-header label.checkbox {
        margin: 5px 0;
    }
    .page-header input[type=text] {
        margin-bottom: 15px;
    }
    .page-header input[type=submit] {
        float: none;
    }
    #top {
        position: relative;
    }
    #top nav {
        padding-bottom: 10px;
        text-align: center;
        display: none;
        border: 0;
        margin: 0;
        position: absolute;
        top: 71px;
        z-index: 10;
        width: 100%;
        left: 0;
        background: #f6f6f6;
        -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
    }
    #top nav ul {
        background-image: none;
    }
    #top nav ul li {
        margin-right: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        display: block;
        text-align: center;
        padding: 10px 0;
    }
    #top nav ul li .link {
        margin: 0 auto;
    }
    #top nav ul li .link.statybu {
        width: 132px;
        background-image: url('/img/front/statybu/logo.png');
    }
    #top nav ul li .link.ukio {
        width: 125px;
        background-image: url('/img/front/ukio/logo.png');
    }
    #top nav ul li .link.komunaline {
        width: 128px;
        background-image: url('/img/front/komunaline/logo.png');
    }
    #top nav ul li .link.misko {
        width: 130px;
        background-image: url('/img/front/misko/logo.png');
    }
    #top nav ul.langs {
        float: none;
    }
    #top nav ul.langs li {
        border-bottom: 0;
    }
    body.index header {
        height: 665px;
    }
    body.index .page-header {
        margin-top: -644px;
    }
    footer .information {
        text-align: center;
    }
    footer .information strong {
        display: block;
    }
    footer .information .author {
        margin-top: 5px;
        float: none;
    }
    form.contact .form-content .item {
        margin-top: 15px;
    }
    form.contact .form-content .item:first-of-type {
        margin-top: 0px;
    }
    #content .item-content {
        margin-bottom: 20px;
    }
    #content .item-content:last-of-type {
        margin-bottom: 0;
    }
}
/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
    body.textpage #content .box {
        padding: 20px;
    }
    .page-header .search-options,

    .list-news li .image {
        margin: 0;
        float: none;
        width: 100%;
    }
}
/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
    .product .product-thumbnails li {
        width: 33.3%;
    }
}
@media only screen and (min-width: 767px) {
    #top nav {
        display: block !important;
    }
}

/*#ads {*/
/*position: static;*/
/*}*/

/*.list-popular li .image a,*/
/*.list-popular li .image {*/
/*display: block;*/
/*}*/

/*.list-popular .item .image a img {*/
/*max-width: none;*/
/*position: relative;*/
/*-webkit-transform: translateX(-50%);*/
/*-ms-transform: translateX(-50%);*/
/*transform: translateX(-50%);*/
/*left: 50%;*/
/*height: 125px;*/
/*}*/
.file-upload .content .thumbnail {
    height: auto;
}

.file-upload .content {
    min-height: 73px;
}

.file-upload .content .template-download {
    cursor: move;
}

.file-upload .content .thumbnail .uploaded-image {
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: hidden;
    left: 0;
    right: 0;
}

.file-upload .content .thumbnail.dim .cell {
    opacity: .5;
}

.file-upload .content .thumbnail .error,
.file-upload .content .thumbnail .name {
    display: none;
}

.file-upload .content .thumbnail .size {
    display: none;
}

.file-upload .content .thumbnail .btn {
    position: absolute;
    top: 0;
    margin: 2px;
    background: none;
    padding: 0;
    color: #f00;
    font-size: 15px;
    width: 26px;
    border: 0;
    right: 0;
}

.file-upload .content .thumbnail .uploaded-image .table {
    display: table;
    width: 100%;
    height: 100%;
}

.file-upload .content .thumbnail .uploaded-image .cell {
    display: table-cell;
    vertical-align: middle;
}

.file-upload .content .thumbnail .uploaded-image img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
}

.file-upload .content .thumbnail canvas,
.file-upload .content .thumbnail img {
    display: block;
    width: 100%;
    height: auto;
}

.file-upload .action .count .error {
    color: #f00;
}
header .ads {
    height: auto;
    display: block !important;
    /* arba nuimt hidden klases. */
}

header .ads img {
    max-width: 100%;
    height: auto;
}

#ads .item {
    height: auto;
    text-align: center;
    max-width: 250px;
    margin: 0 auto 20px;
}

#ads .item img {
    max-width: 100%;
    height: auto;
}

body.index .page-header {
    margin-top: 0;
}

.list-popular li .image {
    margin: 0;
    display: table;
    width: 100.2%;
    text-align: center;
}

.list-popular li .item {
    overflow: hidden;
}

.list-popular li .image a {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
}

@media only screen and (max-width: 480px) {
    .list-news li .image {
        height: auto;
    }

    .list-news li .image img {
        display: block;
        width: 100%;
        height: auto;
    }
}
.list-popular .item .image a img {
    max-width: 100%;
    height: auto;
    width: auto;
    max-height: 125px;
    display: inline-block;
}

.file-upload .content .thumbnail {
    margin-bottom: 25px;
    box-shadow: none;
}

body {
    min-width: 320px;
}

body.index {
    position: relative;
}
body.index:before {
    position: absolute;
    top: 86px;
    left: 0;
    right: 0;
    content: "";
    height: 556px;
    background: url('/img/front/misko/bg-header.jpg') no-repeat top center;
    background-size: cover;
}
body.index header {
    background: none;
    height: auto;
}
footer {
    background-size: cover;
}
.product-detail header {
    margin-bottom: -38px;
    height: 198px;

}
.page-header .page-links ul li + li {
    border-left: 1px solid #e9ede9;

}
.page-header .page-links ul li:last-child {
    border-left: 1px solid #e9ede9;

}
.page-header .page-links ul li a img {
    margin-right: 7px;
    margin-top: -1px;
    margin-bottom: 1px;

}
.page-header .page-links ul li a {
    color: rgba(0, 0,0, .5);
    font-size: 13px;
    font-weight: 400;

}
.page-header .page-links ul li {
    height: 24px;
    line-height: 24px;
    padding: 0;
    border-right: 1px solid #fff;
    width: 20%;
}
.page-header .page-links ul li:nth-child(1) {
    width: 160px;
}
.page-header .page-links ul li:nth-child(2) {
    width: 232px;
}
.page-header .page-links ul li:nth-child(3) {
    width: 190px;
}
.page-header .page-links ul li:nth-child(4) {
    width: 175px;
}
.page-header .page-links ul li:nth-child(5) {
    width: 150px;
}
.page-header .page-links {
    margin-bottom: -10px;
    padding-top: 7px;
    margin-top: 6px;
    border: 0;
    width: auto;
    height: 38px;
    background-image: linear-gradient(to top, white 0%, rgba(255, 255, 255, 0.4) 100%);
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.05), inset 0 1px 0 white, 0 1px 0 rgba(0, 0, 0, 0.08);
    position: relative;
    top: 8px;
}
.template-upload .progress  {
    position: absolute;
    top: 50%;
    margin-top: -4px;
    height: 8px;
    left: 0;
    right: 0;
    background: #fff;
}
.template-upload .progress-bar {
    height: 8px;
    display: block;
    background: #ccc;
}
@media only screen and (max-width: 1200px) {
    body.index:before {
        height: 596px;
    }
}
@media only screen and (max-width: 993px) {
    body.index:before {
        height: 603px;
    }
}
.product .product-thumbnails li {
    width: auto !important;
}
@media only screen and (max-width: 767px) {

    .product .product-thumbnails {
        overflow: hidden;
    }
    .product .product-price {
        clear: both;
    }
    .product-detail header {
        height: 135px;
        margin-bottom: -6px;
    }
    body.index:before {
        height: 666px;
    }
    #content .gbox {
        padding: 10px;
        margin: 0 -20px;
    }
    .user-tabs .list-tabs {
        display: table;
        width: 100%;
    }
    .user-tabs .list-tabs li {
        float: none;
        display: table-cell;
        width: auto;
        padding: 0 5px;
    }
    .user-tabs .list-tabs li + li {
        border-left: 0;
    }
    .file-upload .content .thumbnail {
        width: 33.3%;
    }
}

.product .product-thumbnails li .thumbbox {
    width: 72px;
}

.product .product-thumbnails li .thumbbox img {
    max-height: 100%;
}

.list-items .right .manufacturer img {
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}


/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    vertical-align: top;
}

.fancybox-wrap {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8020;
}

.fancybox-skin {
    position: relative;
    background: #fff;
    color: #444;
    text-shadow: none;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

.fancybox-opened {
    z-index: 8030;
}

.fancybox-outer, .fancybox-inner {
    position: relative;
}

.fancybox-inner {
    overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
    -webkit-overflow-scrolling: touch;
}

.fancybox-error {
    color: #444;
    margin: 0;
    padding: 15px;
    white-space: nowrap;
}

.fancybox-image, .fancybox-iframe {
    display: block;
    width: 100%;
    height: 100%;
}

.fancybox-image {
    max-width: 100%;
    max-height: 100%;
}

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url('fancybox_sprite.png');
}

#fancybox-loading {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -22px;
    margin-left: -22px;
    background-position: 0 -108px;
    opacity: 0.8;
    cursor: pointer;
    z-index: 8060;
}

#fancybox-loading div {
    width: 44px;
    height: 44px;
    background: url('fancybox_loading.gif') center center no-repeat;
}

.fancybox-close {
    position: absolute;
    top: -18px;
    right: -18px;
    width: 36px;
    height: 36px;
    cursor: pointer;
    z-index: 8040;
}

.fancybox-nav {
    position: absolute;
    top: 0;
    width: 40%;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    z-index: 8040;
}

.fancybox-prev {
    left: 0;
}

.fancybox-next {
    right: 0;
}

.fancybox-nav span {
    position: absolute;
    top: 50%;
    width: 36px;
    height: 34px;
    margin-top: -18px;
    cursor: pointer;
    z-index: 8040;
    visibility: hidden;
}

.fancybox-prev span {
    left: 10px;
    background-position: 0 -36px;
}

.fancybox-next span {
    right: 10px;
    background-position: 0 -72px;
}

.fancybox-nav:hover span {
    visibility: visible;
}

.fancybox-tmp {
    position: absolute;
    top: -99999px;
    left: -99999px;
    visibility: hidden;
    max-width: 99999px;
    max-height: 99999px;
    overflow: visible !important;
}

/* Overlay helper */

.fancybox-lock {
    overflow: hidden !important;
    width: auto;
}

.fancybox-lock body {
    overflow: hidden !important;
}

.fancybox-lock-test {
    overflow-y: hidden !important;
}

.fancybox-overlay {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    display: none;
    z-index: 8010;
    background: rgba(0,0, 0, .3);
}

.fancybox-overlay-fixed {
    position: fixed;
    bottom: 0;
    right: 0;
}

.fancybox-lock .fancybox-overlay {
    overflow: auto;
    overflow-y: scroll;
}

/* Title helper */

.fancybox-title {
    visibility: hidden;
    font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
    position: relative;
    text-shadow: none;
    z-index: 8050;
}

.fancybox-opened .fancybox-title {
    visibility: visible;
}

.fancybox-title-float-wrap {
    position: absolute;
    bottom: 0;
    right: 50%;
    margin-bottom: -35px;
    z-index: 8050;
    text-align: center;
}

.fancybox-title-float-wrap .child {
    display: inline-block;
    margin-right: -100%;
    padding: 2px 20px;
    background: transparent;
    /* Fallback for web browsers that doesn't support RGBa */
    background: rgba(0, 0, 0, 0.8);
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    text-shadow: 0 1px 2px #222;
    color: #FFF;
    font-weight: bold;
    line-height: 24px;
    white-space: nowrap;
}

.fancybox-title-outside-wrap {
    position: relative;
    margin-top: 10px;
    color: #fff;
}

.fancybox-title-inside-wrap {
    padding-top: 10px;
}

.fancybox-title-over-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    padding: 10px;
    background: #000;
    background: rgba(0, 0, 0, .8);
}
form input[type=text], form input[type=password], form input[type=number], form textarea {
    padding: 0 5px;
    font-size: 12px;
    cursor: pointer;
    width: 100%;
    height: 25px;
    border: 1px solid #ced2cc;
    border-radius: 3px;
    display: block;
}

select {
    background: white;
    padding: 0 5px;
    font-size: 12px;
    cursor: pointer;
    width: 100%;
    height: 25px;
    border: 1px solid #ced2cc;
    border-radius: 3px;
    display: block;
}

#top .langs li a.active{
    background: #489D44;
    color: #fff;
    text-decoration: none;
    border: 1px solid #489D44;
}

.list-popular li .title {
    height: 36px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-popular li .title a,
.list-popular li .price a {
    color: #666666;
}
.list-popular li .price a{
    margin-top: 5px;
    padding-top: 5px;
    border-top: 1px solid #e5e5e5;
    color: #489D44;
    font-weight: 700;
}


.list-items .image img{
    max-height: 100%;
    max-width: auto;
}

.list-popular .item .image {
    overflow: hidden;
}

.list-popular .item .image a img {
    width: auto;
    height: 100%;
}
.page-header {
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.pagination>.active>a, .pagination>.active>a:focus, .pagination>.active>a:hover, .pagination>.active>span, .pagination>.active>span:focus, .pagination>.active>span:hover {
    background-color: #489D44;
    border-color: #489D44;
}

.pagination>li>a:focus, .pagination>li>a:hover, .pagination>li>span:focus, .pagination>li>span:hover {
    color: #489D44;
}

.pagination>li>a, .pagination>li>span {
    color: #489D44;
}

.slogan {
    max-width: 200px;
}

form.form-filter label.checkbox {
    line-height: 23px;
}

.checkbox input[type=checkbox], .checkbox-inline input[type=checkbox], .radio input[type=radio], .radio-inline input[type=radio] {
    position: relative;
    margin-left: 0;
}

.group .checkbox, .group .radio {
    margin-top: 24px;
}

.page-header .search-options {
    background-color: #489D44;
    border-color: #489D44;
    float: right;
    height: 40px;
    line-height: 40px;
}

.sort select {
    padding: 0 5px;
}

.btn-list a{
    display: block;
    color: #555;
    text-decoration: none;
    text-align: center;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    padding: 10px;
}

.btn-list a:focus,
.btn-list a:hover {
    color: #555;
    text-decoration: none;
    background-color: #f5f5f5;
}


.list-popular li .image a {
    background-position: center;
    background-size: cover;
}

.list-items .desc {
    min-height: 33px;
}

.button-sm{
    color: #ffffff !important;
    height: 20px;
    line-height: 20px;
    padding: 0 5px;
}

.list-items .content {
    max-width: 450px;
}

.page-header #main-filters {
    min-height: 219px;
}

.checkbox label, .radio label{
    padding-left: 0px;
}

.no-ad.index:before {
    height: 366px;
}
.cat-list a {
    color: inherit;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 5px;
    text-decoration: none;
}
.cat-list .current a,
.cat-list a:hover {
    color: #489D44;
    border-color: #489D44;
}
.cat-list .current a {
    font-weight: bold;
}
.cat-list {
    color: #666;
    font-size: 0;
    font-weight: 400;
    padding: 20px 0 0;
    line-height: 28px;
}
.cat-list li,
.cat-list ul {
    padding: 0;
    margin: 0;
    list-style: none;
}
.cat-list ul {
    columns: 4;
    padding-bottom: 20px;
    vertical-align: top;
    -webkit-columns: 4;
    -moz-columns: 4;
}
.cat-list li a:hover:before {
    background-color: #489D44;
}
.cat-list li a:before {
    position: absolute;
    left: 0;
    top: 15px;
    content: "";
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    border: 1px solid transparent;
}
.cat-list li {
    width: 100%;
    display: inline-block;
    padding-left: 22px;
    padding-right: 5px;
    position: relative;
    font-size: 13px;
    margin-bottom: 14px;
    vertical-align: top;
}
@media only screen and (min-width: 990px) {
    footer .col-lg-6 ul {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
    }
    footer .col-lg-6 ul li {
        width: 100%;
        display: inline-block;
        vertical-align: top;
        padding-right: 8px;
    }
}
@media only screen and (max-width: 991px) {
    .no-ad.index:before {
        height: 405px;
    }
}
@media only screen and (max-width: 1199px) {
    .cat-list ul {
        columns: 3;
        -webkit-columns: 3;
        -moz-columns: 3;
    }
}
@media only screen and (max-width: 767px) {
    .no-ad.index:before {
        height: 426px;
    }
    footer .col-lg-6 ul,
    .cat-list ul {
        columns: 2;
        -webkit-columns: 2;
        -moz-columns: 2;
    }
}
@media only screen and (max-width: 480px) {
    footer .col-lg-6 ul,
    .cat-list ul {
        columns: 1;
        -webkit-columns: 1;
        -moz-columns: 1;
    }
}

.button.state-open {
    background-color: #fff;
}

.remember-advert.remembered{
    color: #489D44 !important;
    font-weight: bold !important;
}

.file-upload .thumbnail {
    border: none;
}

.list-items .right .manufacturer {
    position: relative;
    align-content: center;
    text-align: center;
    color: #d9d9d9;
    margin: 5px;
    border: 1px solid #eeeeee;
    background-color: #ffffff;
}

.list-items .right .manufacturer a.no-logo {
    color: #999999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
}

.product-info .manufacturer img {
    width: 100px;
    padding: 5px;
    border: 1px solid #d9d9d9;
}

.side-menu .active a {
    border-radius: 0;
}

.input-group-addon {
    font-size: 10px;
    padding-left: 3px;
    padding-right: 3px;
}

a {
    color: #489D44;
}


.list-items .image {
    height: auto;
}
.list-items .image img { max-height: none; width: 100%; height: auto; }

.fb-page.fb_iframe_widget {
    margin-bottom: 20px;
}

#ads .item.item-white {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    padding: 7px;
}

#ads .item.item-white .section-title > div{
    padding-bottom: 0;
    border-bottom: none;
}

#ads .item.item-white input{
    height: 25px;
    padding: 5px 5px;
    font-size: 12px;
    cursor: pointer;
    width: 100%;
    border: 1px solid #ced2cc;
    border-radius: 3px;
    display: block;
    margin-bottom: 7px;
}


#ads .item.item-white button[type=submit]{
    float: none;
}

.alert.alert-success {
    background-image: none;
}

.alert.alert-warning {
    background-image: none;
}
